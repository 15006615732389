<template>
  <v-row style="height: 100%" align="center" justify="center">
    <v-col cols="12" xl="10">
      <login />
    </v-col>
  </v-row>
</template>
<script>
import Login from '../components/Login'
export default {
  name: 'Account',
  components: {
    Login,
  },
  computed: {
    user() {
      return this.$store.state.user
    },
  },
  mounted(){
    if (!this.user == null){
      //redirect to home
    }
  }
}
</script>
