<template>
  <v-row v-on:keyup.enter="login" justify="center">
    <v-col cols="12" md="6">
      <v-card>
        <v-card-title>
          Sign In
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-text-field
              v-model="email"
              label="Email address"
              :rules="emailRules"
            />
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              :rules="passwordRules"
            />
          </v-form>
          <v-row>
            <v-col cols="12">
              <v-btn
                :disabled="!valid"
                style="min-width: 100%"
                class="ma-2"
                color="primary"
                @click="login"
                >Login</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'Login',
  data: () => ({
    password: null,
    email: null,
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [(v) => !!v || 'Password is required'],
    valid: null,
  }),
  methods: {
    login() {
      this.$store.dispatch('login', {
        identifier: this.email,
        password: this.password,
      })
    },
  },
}
</script>
